<template>
  <!-- 关于我们 -->
  <div class="about">
    <Banner v-bind="bannerProps" />
    <!-- 公司介绍 -->
    <div id="about0" class="icd">
      <div class="left">
        <h2>
          COMPANY PROFILE
        </h2>
        <h3>
          企业介绍
        </h3>
        <div class="txt">
          <div v-html="aboutList.aboutus" />
        </div>
      </div>
      <div class="right">
        <img src="https://img.kcgeis.com/5841733917.png" alt="">
      </div>
    </div>

    <!-- 企业文化 -->
    <div id="about1" class="culture">
      <div class="left">
        <img src="https://img.kcgeis.com/2455395700.png" alt="">
      </div>
      <div class="right">
        <h2>
          ENTERPRISE CULTURE
        </h2>
        <h3>
          企业文化
        </h3>
        <div class="txt">
          <span>
            <h4>核心价值观</h4>
            <p>团结、诚信、创新、共赢</p>
          </span>
          <span>
            <h4>企业精神</h4>
            <p>爱岗敬业创佳绩、求真务实谋发展</p>
          </span>
          <span style="width: 100%;">
            <h4>经营理念</h4>
            <div class="idea">
              <span style="background-color: #f4f4f4;">
                <h5>以技术为核心</h5>
                <p>
                  作为一家装配式建筑综合服务商，技术永远是核心。用心研发技术和产品，推动技术创新、保持技术优势是安雅园立于不败之地的保证。
                </p>
              </span>
              <span style="background-color: #e3e3e3;">
                <h5>以品质为保障</h5>
                <p>
                  品质是企业生产和发展的基石；用工匠精神来做设计、搞生产；练好内功，铸好品质，不打价格战，不搞恶性竞争，既是对自身负责，也是对客户和社会负责。
                </p>
              </span>
              <span style="background-color: #f4f4f4;">
                <h5>以服务为宗旨</h5>
                <p>
                  公司必须建立完善的设计、生产、销售体系，做好售前、售中和售后的全过程服务，不断扩大服务面、提高服务质量。诚信经营、用心服务以此赢得客户的信赖！
                </p>
              </span>
            </div>
          </span>
          <span>
            <h4>企业愿景</h4>
            <p>成为最值得信赖的装配式建筑综合服务商。</p>
          </span>
        </div>
      </div>
    </div>

    <!-- 团队精英  -->
    <div id="about2" class="elite">
      <div class="Box">
        <!-- 标题 -->
        <span class="Subtitle">ELITE TEAM</span>
        <span class="Title">精英团队</span>
      </div>
      <div class="elite-list">
        <template v-for="(item , index ) in teamList">
          <div :key="index" class="team-list">
            <img :src="item.img" alt="">
            <div class="team-title">
              {{ item.title }}
            </div>
            <div class="team-detail">
              {{ item.detail }}
            </div>
          </div>
        </template>
      </div>
    </div>
    <!-- 生产基地 -->
    <div id="about3" class="base">
      <div class="left">
        <h2>
          PRODUCTION BASE
        </h2>
        <h3>
          生产基地
        </h3>
      </div>
      <div class="txt">
        {{ aboutList.base }}
      </div>

      <div class="content">
        <template v-for="item in aboutList.img">
          <div :key="item.id" class="baseimg">
            <img :src="item.url" class="img">
          </div>
        </template>
      </div>
    </div>

    <!-- 发展历程 -->
    <div id="about4" class="history">
      <div class="top">
        <h2>
          DEVELOPMENT HISTORY
        </h2>
        <h3>
          发展历程
        </h3>
      </div>
      <div class="bottom">
        <div class="subtxt">
          <span>
            <h3>2018年5月</h3>
            <p>
              湖南长沙生产基地成立，拥有仓储面积5500平方，建立初始生产基地。
            </p>
          </span>
          <span>
            <h3>2020年5月</h3>
            <p>
              湖南浏阳生产基地成立，仓储面积2000平方，稳固华中供应链。
            </p>
          </span>
          <span>
            <h3>2021年4月</h3>
            <p>
              浙江绍兴生产基地成立，仓储面积4000平方，逐步完善华东地区工厂布局。
            </p>
          </span>
          <span>
            <h3>2021年7月</h3>
            <p>
              公司举办三周年庆典“暨行业客户合作交流会”。
            </p>
          </span>
        </div>
        <div class="min">
          <template v-for="(item, index) in picList">
            <div :key="index" class="por">
              <img :src="item.imgUrl" alt="">
              <span>{{ item.txt }}</span>
            </div>
          </template>
        </div>
        <div class="suptxt">
          <span>
            <h3>2018年5月</h3>
            <p>
              广东安雅园新型材料科技有限公司成立
            </p>
          </span>
          <span>
            <h3>2019年6月</h3>
            <p>
              四川成都生产基地成立，仓储面积3000平方，开拓西南市场。
            </p>
          </span>
          <span>
            <h3>2020年2月</h3>
            <p>
              广东佛山生产基地成立，仓储面积3000平方，进军华南市场。
            </p>
          </span>
          <span>
            <h3>2021年5月</h3>
            <p>
              广东江门生产基地成立，仓储面积2000平方。
            </p>
          </span>
        </div>
      </div>
    </div>

    <!-- 伙伴 -->
    <div id="about5" class="friends">
      <div class="Box">
        <!-- 标题 -->
        <span class="Subtitle">STRATEGIC PARTNERS</span>
        <span class="Title">战略合作伙伴</span>
      </div>
      <div class="content">
        <template v-for="item in List">
          <div :key="item.id" class="partner">
            <RouterLink
              :to="{ path: '/PartnerContent', query: { id: item.id } }"
            >
              <img :src="item.imgUrl" class="img">
            </RouterLink>
          </div>
        </template>
      </div>
    </div>

    <!-- 荣誉资质 -->
    <template v-if="gloryList.length>0">
      <div id="about6" class="honor">
        <div class="Box">
          <!-- 标题 -->
          <span class="Subtitle">HONOR AND QUALIFICATION</span>
          <span class="Title">荣誉资质</span>
        </div>
        <Swiper :options="swiperOptions">
          <template v-for="(item, index) in gloryList">
            <SwiperSlide :key="index">
              <div class="img">
                <img :src="item.imgUrl" alt="">
              </div>
              <p>
                {{ item.gloryName }}
              </p>
            </SwiperSlide>
          </template>
        </Swiper>
      </div>
    </template>

    <!-- 公司相册 -->
    <Photo id="about7" />
  </div>
</template>

<script>
import Banner from '../components/banner.vue';

import { partner, aboutUs, glory } from '../api/about';
import Photo from './Home/components/TeamPhoto.vue';

export default {
  name: 'AboutIndex',
  components: {
    Photo, Banner,
  },
  data() {
    const picList = [
      {
        imgUrl: require('@/assets/icon/fzlcz.png'),

        txt: '1',
      },
      {
        imgUrl: require('@/assets/icon/fzlcz2.png'),

        txt: ' 2',
      },
      {
        imgUrl: require('@/assets/icon/fzlcz3.png'),

        txt: '3',
      },
      {
        imgUrl: require('@/assets/icon/fzlcz.png'),

        txt: '4',
      },
      {
        imgUrl: require('@/assets/icon/fzlcz2.png'),
        txt: '5',
      },
      {
        imgUrl: require('@/assets/icon/fzlcz3.png'),
        txt: '6',
      },
      {
        imgUrl: require('@/assets/icon/fzlcz.png'),
        txt: '7',
      },
      {
        imgUrl: require('@/assets/icon/fzlcz2.png'),
        txt: '8',
      },
    ];
    return {
      bannerProps: {
        backgroundImage: 'https://img.kcgeis.com/5371420370.jpg',
        cnTitle: '关于我们',
        cnTitleColor: '#fff',
        enTitle: 'ABOUT US',
        enTitleColor: '#018835',
      },
      flag: 0,
      List: [],
      aboutList: {
        img: null,
      },
      teamList: [
        {
          img: 'https://img.kcgeis.com/1885855472.png',
          title: '01.采购团队',
          detail: '国际化买手，以更优惠的价格买到更优质的原材。',
        },
        {
          img: 'https://img.kcgeis.com/1704220418.png',
          title: '02.研发团队',
          detail: '与时俱进研发智能整装产品，不断提高品牌的竞争力。',
        },
        {
          img: 'https://img.kcgeis.com/9339187554.png',
          title: '03.品控团队',
          detail: '严苛把好每一关，以期给消费者提供行业潮流精品。',
        },
        {
          img: 'https://img.kcgeis.com/8756036771.png',
          title: '04.设计团队',
          detail: '为每一位消费者定制个性化、理想化的装修方案。',
        },
        {
          img: 'https://img.kcgeis.com/9006437146.png',
          title: '05.市场团队',
          detail: '精心筛选合作伙伴，携手共享家装大市场红利。',
        },
        {
          img: 'https://img.kcgeis.com/0131662911.png',
          title: '06.物流团队',
          detail: '高效准时的将商品送至消费者及合作者手中。',
        },
        {
          img: 'https://img.kcgeis.com/5304692508.png',
          title: '07.售后团队',
          detail: '完善的售后机制，为每一次的成交保驾护航。',
        },
        {
          img: 'https://img.kcgeis.com/8496097227.png',
          title: '08.培训团队',
          detail: '全方位体系化运营培训，让合作商快速上手经营。',
        },
      ],
      picList,
      gloryList: [],
      swiperOptions: {
        speed: 5000,
        observer: true,
        observeSlideChildren: true,
        watchOverflow: true,
        // slidesPerView: 'auto',
        spaceBetween: 60,
        loop: true,
        autoplay: {
          delay: 0,
          disableOnInteraction: false,
        },
      },
    };
  },

  async created() {
    await this.getData();
    await this.getAboutData();
    await this.glory();
  },
  methods: {
    async getData() {
      const res = await partner();
      this.List = res.data;
    },
    async getAboutData() {
      const res = await aboutUs();
      this.aboutList = res.data[0];
      this.aboutList.img =
        res.data[0].img &&
        res.data[0].img.split(';').map(item => ({ url: item }));
    },
    async glory() {
      try {
        const res = await glory();
        this.gloryList = res.data;
      } catch (error) {
        this.$message.error(error);
      }
    },
  },
};
</script>
 
<style lang="scss" scoped>

.about {
  margin-bottom: 50px;

  .icd {
    width: 100%;
    padding: 50px;
    background-image: url("https://img.kcgeis.com/2257019649.jpg");
    display: flex;
    align-items: center;
    justify-content: space-around;
    .left {
      width: 40vw;
      height: 100%;
      h2 {
        font-size: 50px;
        color: #018835;
        font-weight: bold;
        margin-bottom: 20px;
      }
      h3 {
        font-size: 30px;
        color: #000;
        font-weight: bold;
        margin-bottom: 25px;
      }
    }
    .right {
      width: 40vw;
      img {
        height: 100%;
        width: 100%;
        object-fit: cover;
      }
    }
  }
  .culture {
    margin: 30px auto 30px;
    width: 1200px;
    height: 750px;
    display: flex;
    justify-content: center;
    .left {
      width: 50%;
      display: flex;
      align-items: center;
      padding: 0 25px 0 0;
      img {
        height: 80%;
        width: 100%;
        object-fit: cover;
      }
    }
    .right {
      width: 50%;
      padding: 60px 0 0 25px;
      h2 {
        font-size: 50px;
        color: #018835;
        font-weight: bold;
        margin-bottom: 20px;
      }
      h3 {
        font-size: 30px;
        color: #000;
        font-weight: bold;
        margin-bottom: 25px;
      }
      .txt {
        display: flex;
        flex-wrap: wrap;
        span {
          width: 50%;
          .idea {
            width: 90%;
            display: flex;
            margin-bottom: 20px;
            span {
              margin: 10px 5px 0;
              padding: 10px;
              h5 {
                font-size: 16px;
                font-weight: bold;
                // margin-bottom: 10px;
                color: #000;
              }
              p {
                font-size: 14px;
                line-height: 2;
                color: #434343;
                margin: 0;
              }
            }
          }
          h4 {
            font-size: 20px;
            font-weight: bold;
            margin-bottom: 10px;
            color: #000;
          }
          p {
            font-size: 14px;
            line-height: 2;
            color: #434343;
            margin-bottom: 30px;
            text-align: justify;
          }
        }
      }
    }
  }
  .elite {
    width: 1200px;
    margin: 0 auto;
    .elite-list {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;
      .team-list {
        width: 250px;
        margin: 20px ;
        .team-title {
          text-align: center;
          line-height: 45px;
          margin: 10px 0;
          font-size: 24px;
          font-weight: 800;
          color: #383838;
          margin: 10px 0 ;
        }
        .team-detail {
          line-height: 28px;
          font-size: 16px;
          color: #8a8a8a;
        }
      }
    }
  }
  .base {
    width: 1200px;
    // height: 700px;
    margin: 60px auto 0;
    .left {
      h2 {
        font-size: 50px;
        color: #018835;
        font-weight: bold;
        margin-bottom: 20px;
      }
      h3 {
        font-size: 30px;
        color: #000;
        font-weight: bold;
        margin-bottom: 25px;
      }
    }
    .txt {
      margin: 10px;
      //overflow: hidden;
      width: 1200px;
      word-break: break-all;
      word-wrap: break-word;
    }
    .content {
      margin: 0 auto;
      width: 1200px;
      height: 400px;
      display: flex;
      justify-content: space-around;
      flex-wrap: wrap;
      // background-color: pink;
      overflow: hidden;
      .baseimg {
        margin: 20px;
        width: 150px;
        height: 150px;
        .img {
          height: 100%;
          width: 100%;
          object-fit: cover;
        }
      }
    }
  }
  .history {
    background-image: url("https://img.kcgeis.com/4061184773.jpg");
    width: 100%;
    height: 800px;
    padding-top: 50px;
    .top {
      width: 1200px;
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-end;

      h2 {
        font-size: 50px;
        color: #018835;
        font-weight: bold;
        margin-bottom: 20px;
      }
      h3 {
        font-size: 30px;
        color: #000;
        font-weight: bold;
        margin-bottom: 25px;
      }
    }
    .bottom {
      width: 1200px;
      height: 520px;
      background-color: #fff;
      margin: 0 auto;
      padding: 60px 0;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      .subtxt {
        width: 100%;
        display: flex;
        justify-content: space-between;
        padding-left: 150px;
        span {
          width: 156px;
          h3 {
            font-size: 20px;
            color: #000;
            margin-bottom: 10px;
            text-align: center;
            font-weight: bold;
          }
          p {
            font-size: 14px;
            color: #636363;
          }
        }
      }
      .min {
        width: 100%;
        display: flex;
        justify-content: space-around;
        .por {
          position: relative;

          img {
            height: 120px;
          }
          span {
            font-size: 24px;
            color: #fff;
            font-weight: bold;
            position: absolute;
            top: 45px;
            left: 45px;
          }
        }
      }
      .suptxt {
        width: 100%;
        display: flex;
        justify-content: space-between;
        padding-right: 140px;
        span {
          width: 156px;
          h3 {
            font-size: 20px;
            color: #000;
            margin-bottom: 10px;
            text-align: center;
            font-weight: bold;
          }
          p {
            font-size: 14px;
            color: #636363;
          }
        }
      }
    }
  }
  .friends {
    width: 100%;
    .content {
      margin: 30px auto 0;
      width: 1200px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;
      overflow: hidden;
      .partner {
        border: 1px solid #eee;
        // margin: 20px;
        width: 200px;
        height: 150px;
        .img {
          width: 100%;
          object-fit: cover;
        }
      }
    }
  }
  .honor {
    background: url("https://img.kcgeis.com/6993183224.jpg");
    .swiper-container {
      height: 400px;
      width: 1200px;
      margin-top: 25px;
      ::v-deep .swiper-wrapper {
        transition-timing-function: cubic-bezier(1, 1, 1, 1) !important;
        .swiper-slide {
          height: 350px;
          width: auto;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          .img {
            height: 100%;
            width: auto;

            img {
              height: 100%;
              width: auto;
              object-fit: cover;
            }
          }
          p {
            margin-top: 10px;
            font-size: 20px;
          }
        }
      }
    }
  }
}
</style>
