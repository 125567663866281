<template>
  <div>
    <template v-if="picList.length>0">
      <div class="photo">
        <div class="Box">
          <!-- 标题 -->
          <span class="Subtitle">COMPANY ALBUM</span>
          <span class="Title">公司相册</span>
        </div>
        <Swiper :options="swiperOptions" class="swiper">
          <template v-for="(item, index) in picList">
            <SwiperSlide :key="index">
              <RouterLink :to="{ name: 'PhotoDetail', query: { id: item.id } }">
                <div class="img">
                  <img :src="item.cover" alt="">
                </div>
              </RouterLink>
            </SwiperSlide>
          </template>
        </Swiper>
        <!-- 底部按钮 -->
        <RouterLink tag="div" to="/photo" class="btn">
          <span>更多服务</span>
        </RouterLink>
      </div>
    </template>
  </div>
</template>

<script>

import { photo } from '@/api/home';


export default {
  name: 'PhoneIndex',

  data() {
    return {
      picList: [],
      swiperOptions: {
        loop: true,
        slidesPerView: 4,
        speed: 2000,
        autoplay: {
          delay: 100,
          disableOnInteraction: false,
        },
        slidesOffsetBefore: 100,
        slidesOffsetAfter: 100,
        centerInsufficientSlides: true,
      },
    };
  },
  created() {
    this.photo();
  },
  methods: {
    async photo() {
      try {
        const res = await photo();
        this.picList = res.data;
      } catch (error) {
        this.$message.error(error);
      }
    },
  },
};
</script>

<style lang="scss" scoped>

.photo {
  background-color: rgb(255, 255, 255);
  height: 100%;
  padding-bottom: 100px;
}

.swiper {
  height: 200px;
  width: 100%;
  margin-top: 20px;

  .img {
    width: 300px;
    height: 100%;

    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }
}

.el-carousel {
  margin-top: 50px;
}
.el-carousel__item {
  background-color: #000;
}

</style>
